<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <LoadingMessage v-if="isLoading"></LoadingMessage>

        <ErrorMessage v-if="error" :error="error" class="my-3"></ErrorMessage>

        <div v-if="!isLoading && !error && client" class="my-3">
          <ul class="nav nav-tabs mb-3">
            <li v-for="(item, itemIndex) in items" :key="`key-${itemIndex}`" class="nav-item">
              <a
                @click="activeItem = item"
                :class="activeItem === item ? 'text-dark active' : 'text-muted'"
                class="nav-link text-capitalize"
                role="button"
              >{{ item }}</a>
            </li>
          </ul>
          <div class="mt-3">
            <!-- GOALS -->
            <UserProfileNote
              @update="goal = $event"
              v-show="activeItem === 'goals'"
              :client="client"
              :metric="'goal'"
              :title="'Goals'"
              :content="goal"
            ></UserProfileNote>

            <!-- LIMITATIONS -->
            <UserProfileNote
              @update="limitation = $event"
              v-show="activeItem === 'limitations'"
              :client="client"
              :metric="'limitation'"
              :title="'Limitations'"
              :content="limitation"
            ></UserProfileNote>

            <!-- EQUIPMENT -->
            <UserProfileNote
              @update="equipment = $event"
              v-show="activeItem === 'equipment'"
              :client="client"
              :metric="'equipment'"
              :title="'Equipment'"
              :content="equipment"
            ></UserProfileNote>

            <!-- NOTES -->
            <UserProfileNote
              @update="note = $event"
              v-show="activeItem === 'notes'"
              :client="client"
              :metric="'note'"
              :title="'Notes'"
              :content="note"
              :isQuestionnaire="true"
            ></UserProfileNote>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { httpGet } from '@/core/http';

export default {
  name: 'ClientProfile',
  components: {
    LoadingMessage: () => import('@/components/message/LoadingMessage'),
    ErrorMessage: () => import('@/components/message/ErrorMessage'),
    UserProfileNote: () => import('@/components/UserProfileNote'),
  },
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  methods: {
    async fetchProfile() {
      this.isLoading = true;
      try {
        const { data: { client } } = await httpGet(`/coaching/client/${this.userId}`);
        const res = await httpGet(`/coaching/client/${this.userId}`);
        const {
          note,
          goal,
          equipment,
          limitation,
        } = res.data;
        this.client = client;
        this.note = note;
        this.goal = goal;
        this.equipment = equipment;
        this.limitation = limitation;
      } catch (err) {
        this.error = err;
      } finally {
        this.isLoading = false;
      }
    },
  },
  data() {
    return {
      isLoading: false,
      error: null,
      client: null,
      note: null,
      goal: null,
      equipment: null,
      limitation: null,
      items: ['goals', 'limitations', 'equipment', 'notes'],
      activeItem: 'goals',
    };
  },
  mounted() {
    this.fetchProfile();
  },
};
</script>
